
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonButton, IonInput} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import useDataService from "../dataservice";

export default {
	name: 'Login',
	components: {
	
		IonContent,
		IonHeader,
	
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonButton,
		IonInput,
	},
	methods: {
		
	},
	setup() {
	const { user, updateUser, updateToken } = useDataService();
    const route = useRoute();
    const folder = ref(route.params.id || 'Профиль');
    const matchedFolder = computed(() => route.params.id);
	const router = useRouter();
	
	const lform = {name:'',pass: ''};
	
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });
	
	const getSubmitForm = () => {
			axios.post('https://beon.fun/api/v1/token',{
				'name': lform.name,
				'password': lform.pass,
				'device_name': 'pwa'
			}).then(function (response) {
				Storage.set({
					key: 'token',
					value: response.data.token,
				});
				updateToken(response.data.token);
				axios.get('https://beon.fun/api/v1/test',{
					headers: { Authorization: "Bearer " + response.data.token}
				})
				.then(function(response){
					const userValue = JSON.stringify({
							id: response.data.id,
							name: response.data.name,
							nickname: response.data.nickname,
							avatar: "//i0.beon.fun/" + response.data.path + "/avatars/" + response.data.avatar.idx + "." + response.data.avatar.ext,
						});
					Storage.set({
						key: 'user',
						value: userValue
					});
					updateUser(userValue);
					router.replace('/blog/' + response.data.name);
				}).catch(function(error){
					alert(error);
				});
			}).catch(function (error) {
				console.log(error);
			});
		};
	
	Storage.get({ key: 'token' }).then((val) => {
		if(val.value){
			axios.get('https://beon.fun/api/v1/test',{
				headers: { Authorization: "Bearer " + val.value}
			})
			.then(function(response){
				router.replace('/blog/' + response.data.name);
			});
		}
	});
	
    return { folder, lform, getSubmitForm, user, updateUser }
  }
}


