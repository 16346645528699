<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>

        <ion-title>Войти в аккаунт</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Войти в аккаунт</ion-title>
        </ion-toolbar>
      </ion-header>
    
		<div id="container">
			<form @submit.prevent v-on:subit="getSubmitForm()">
				<div text-center>
					<h3>Введите логин и пароль</h3>
				</div>
				<div>
					<ion-item>
					<ion-input name="username" type="text" placeholder="Логин" required v-model="lform.name"></ion-input>
					</ion-item>
					<ion-item>
					<ion-input name="password" type="password" placeholder="Пароль" required v-model="lform.pass"></ion-input>
					</ion-item>
				</div>
				<div style="text-align: center">
					<ion-button size="large" type="submit" v-on:click="getSubmitForm()">Войти</ion-button>
				</div>
				<div style="text-align: center; margin-top: 50px">
					Нет аккаунта? <a href="//beon.fun/register">Зарегистрироваться</a>
				</div>
			</form>
		</div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.ion-page {
	padding: 1%;
}
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post {
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
.profile__items {
	width: 100%;
}
.profile__items > div, .profile__main > span  {
	display: inline-block;
	width: 50%;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonButton, IonInput} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import useDataService from "../dataservice";

export default {
	name: 'Login',
	components: {
	
		IonContent,
		IonHeader,
	
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonButton,
		IonInput,
	},
	methods: {
		
	},
	setup() {
	const { user, updateUser, updateToken } = useDataService();
    const route = useRoute();
    const folder = ref(route.params.id || 'Профиль');
    const matchedFolder = computed(() => route.params.id);
	const router = useRouter();
	
	const lform = {name:'',pass: ''};
	
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });
	
	const getSubmitForm = () => {
			axios.post('https://beon.fun/api/v1/token',{
				'name': lform.name,
				'password': lform.pass,
				'device_name': 'pwa'
			}).then(function (response) {
				Storage.set({
					key: 'token',
					value: response.data.token,
				});
				updateToken(response.data.token);
				axios.get('https://beon.fun/api/v1/test',{
					headers: { Authorization: "Bearer " + response.data.token}
				})
				.then(function(response){
					const userValue = JSON.stringify({
							id: response.data.id,
							name: response.data.name,
							nickname: response.data.nickname,
							avatar: "//i0.beon.fun/" + response.data.path + "/avatars/" + response.data.avatar.idx + "." + response.data.avatar.ext,
						});
					Storage.set({
						key: 'user',
						value: userValue
					});
					updateUser(userValue);
					router.replace('/blog/' + response.data.name);
				}).catch(function(error){
					alert(error);
				});
			}).catch(function (error) {
				console.log(error);
			});
		};
	
	Storage.get({ key: 'token' }).then((val) => {
		if(val.value){
			axios.get('https://beon.fun/api/v1/test',{
				headers: { Authorization: "Bearer " + val.value}
			})
			.then(function(response){
				router.replace('/blog/' + response.data.name);
			});
		}
	});
	
    return { folder, lform, getSubmitForm, user, updateUser }
  }
}


</script>